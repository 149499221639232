import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

require("@tabler/core");
require("@tabler/core/dist/css/tabler.min.css");
require("./styles/main.scss");

createApp(App).use(store).use(router).mount("#app");
